var exports = {};
const PooledResourceStateEnum = {
  ALLOCATED: "ALLOCATED",
  // In use
  IDLE: "IDLE",
  // In the queue, not in use.
  INVALID: "INVALID",
  // Failed validation
  RETURNING: "RETURNING",
  // Resource is in process of returning
  VALIDATION: "VALIDATION" // Currently being tested

};
exports = PooledResourceStateEnum;
export default exports;