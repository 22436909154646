import _Pool from "./lib/Pool";
import _Deque from "./lib/Deque";
import _PriorityQueue from "./lib/PriorityQueue";
import _DefaultEvictor from "./lib/DefaultEvictor";
var exports = {};
const Pool = _Pool;
const Deque = _Deque;
const PriorityQueue = _PriorityQueue;
const DefaultEvictor = _DefaultEvictor;
exports = {
  Pool: Pool,
  Deque: Deque,
  PriorityQueue: PriorityQueue,
  DefaultEvictor: DefaultEvictor,
  createPool: function (factory, config) {
    return new Pool(DefaultEvictor, Deque, PriorityQueue, factory, config);
  }
};
export default exports;
export const createPool = exports.createPool;
const _Pool2 = exports.Pool,
      _Deque2 = exports.Deque,
      _PriorityQueue2 = exports.PriorityQueue,
      _DefaultEvictor2 = exports.DefaultEvictor;
export { _Pool2 as Pool, _Deque2 as Deque, _PriorityQueue2 as PriorityQueue, _DefaultEvictor2 as DefaultEvictor };