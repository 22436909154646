import _DoublyLinkedListIterator from "./DoublyLinkedListIterator";
var exports = {};
const DoublyLinkedListIterator = _DoublyLinkedListIterator;
/**
 * Thin wrapper around an underlying DDL iterator
 */

class DequeIterator extends DoublyLinkedListIterator {
  next() {
    const result = super.next(); // unwrap the node...

    if (result.value) {
      result.value = result.value.data;
    }

    return result;
  }

}

exports = DequeIterator;
export default exports;